export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    image: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: { },
  data() {
    return { }
  },
  methods: {
    onClose () {
      this.$emit('onImageCloseHandler', {
        status: false
      })
    },
  }
}
